import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Define a higher-order component (HOC) to protect routes
const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { user } = useSelector((state) => state.auth);

  console.log('ProtectedRoute: Checking user admin status');
  // console.log('User:', user);
  // console.log('Is Admin:', user?.isAdmin);

  // Check if the user is an admin
  if (!user?.isAdmin) {
    console.log('ProtectedRoute: User is not an admin, redirecting to home');
    // If not an admin, redirect to another route (e.g., home)
    return <Navigate to="/" />;
  }

  console.log('ProtectedRoute: User is an admin, rendering component');
  // If the user is an admin, render the original component
  return <Element {...rest} />;
};

export default ProtectedRoute;
import axios from 'axios'
console.log("process.env.REACT_APP_DEV_API_URL? - " + process.env.REACT_APP_DEV_API_URL)

console.log("process.env.REACT_APP_DEV_API_URL? - " + process.env.REACT_APP_DEV_API_URL)
const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL + "jobs/" : process.env.REACT_APP_DEV_API_URL + "jobs/";

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

// Get random job

const getRandomJob = async () => {
  console.log("getRandomJob activated");
  console.log(`API_URL = ${API_URL}`);
  const response = await axios.get(API_URL + `random`);
  const data = response.data;

  if (typeof data === 'string') {
    return capitalizeWords(data);
  } else if (Array.isArray(data)) {
    return data.map(item => (typeof item === 'string' ? capitalizeWords(item) : item));
  } else if (typeof data === 'object' && data !== null) {
    for (let key in data) {
      if (typeof data[key] === 'string') {
        data[key] = capitalizeWords(data[key]);
      }
    }
    return data;
  }

  return data;
};

// Create new job

const createJob = async (incomingData, token) => {
  console.log("createJob activated")
  console.log(`API_URL = ${API_URL}`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + `create`, incomingData, config)

  return response.data
}

// Edit job

const editJob = async (incomingData, jobId, token) => {
  console.log("editJob activated")
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL + jobId, incomingData, config)
  return response.data
}

// Get jobs that need verification

const findJobsToVerify = async (token) => {
  console.log("Finding jobs that need verifying");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + `verify`, config)
  // console.log(`service response.data: ${JSON.stringify(response.data)}`)
  return response.data
}

const deleteJob = async (jobId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const response = await axios.delete(API_URL + jobId, config)
    return response.data
  } catch (error) {
    throw error; // Throw any errors
  }
}

const verifyJob = async (jobId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const updatedStatus = { verified: true };
  const response = await axios.put(API_URL + jobId, updatedStatus, config)
  return response.data
}

const getAllJobs = async (token) => {
  console.log(`getAllJobs activated`)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + `all`, config)
  // console.log(`getAllJobs response = ${JSON.stringify(response.data)}`)
  return response.data
}

const jobService = {
  getRandomJob,
  createJob,
  findJobsToVerify,
  deleteJob,
  verifyJob,
  getAllJobs,
  editJob
}

export default jobService
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { findJobsToVerify, deleteJob, verifyJob, getAllJobs, editJob } from '../features/job/jobSlice';
import { findPlacesToVerify, deletePlace, verifyPlace, editPlace } from '../features/place/placeSlice';
import { Button } from 'react-bootstrap'
import AdminItemTable from '../components/AdminItemTable';
import { deleteThing, findThingsToVerify, verifyThing, editThing } from '../features/thing/thingSlice';
import { deletePrompt, findPromptsToVerify, verifyPrompt, editPrompt, getAllPrompts } from '../features/prompt/promptSlice';
import { deleteEmotion, findEmotionsToVerify, verifyEmotion, editEmotion } from '../features/emotion/emotionSlice';
import { MdCheck, MdDeleteForever, MdModeEdit } from 'react-icons/md';

const Admin = () => {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [places, setPlaces] = useState([]);
  const [things, setThings] = useState([]);
  const [emotions, setEmotions] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [editedItem, setEditedItem] = useState(null);

  useEffect(() => {
    dispatch(findPlacesToVerify())
      .then((response) => {
        // console.log(`response.payload ${JSON.stringify(response.payload)}`)
        // Transform the data structure before setting it in the state

        const transformedPlaces = response.payload.place.map((place, index) => ({
          ...place,
          creatorName: response.payload.user[index],
          name: place.name // Map 'name' to 'name'
        }));
        setPlaces(transformedPlaces);
      })
      .catch((error) => {
        console.error('Error fetching places:', error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(findJobsToVerify())
      .then((response) => {
        // console.log(`Admin response ${JSON.stringify(response)}`)
        const transformedJobs = response.payload.job.map((job, index) => ({
          ...job,
          creatorName: response.payload.user[index],
          name: job.name
        }));
        // console.log(`transformedJobs: ${JSON.stringify(transformedJobs)}`)
        setJobs(transformedJobs);
      })
      .catch((error) => {
        console.error('Error fetching jobs to verify:', error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(findThingsToVerify())
      .then((response) => {
        const transformedThings = response.payload.thing.map((thing, index) => ({
          ...thing,
          creatorName: response.payload.user[index],
          name: thing.name
        }));
        setThings(transformedThings);
      })
      .catch((error) => {
        console.error('Error fetching things to verify:', error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(findEmotionsToVerify())
      .then((response) => {
        const transformedEmotions = response.payload.emotion.map((emotion, index) => ({
          ...emotion,
          creatorName: response.payload.user[index],
          name: emotion.name
        }));
        setEmotions(transformedEmotions);
      })
      .catch((error) => {
        console.error('Error fetching emotions to verify:', error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(findPromptsToVerify())
      .then((response) => {
        const { prompt, user } = response.payload;
        if (prompt && user) {
          const transformedPrompts = prompt.map((promptItem, index) => ({
            ...promptItem,
            creatorName: user[index] // Match the creator name with the user array
          }));
          setPrompts(transformedPrompts);
        } else {
          console.error('Unexpected response structure', response.payload);
        }
      })
      .catch((error) => {
        console.error('Error fetching prompts to verify:', error);
      });
  }, [dispatch]);

  const handleDeleteJob = (item) => {
    const confirmation = window.confirm(`Are you sure you want to delete ${item.name} ? `);
    console.log(`Job to be deleted: ${JSON.stringify(item)}`)
    if (confirmation) {
      dispatch(deleteJob(item._id))
        .then(() => {
          setJobs(prevJobs => prevJobs.filter(job => job._id !== item._id));
        })
        .catch((error) => {
          console.error('Error deleting job:', error);
        });
    }
  }

  const handleDeletePlace = (item) => {
    const confirmation = window.confirm(`Are you sure you want to delete ${item.name} ? `);
    console.log(`Job to be deleted: ${JSON.stringify(item)}`)
    if (confirmation) {
      dispatch(deletePlace(item._id))
        .then(() => {
          setPlaces(prevPlaces => prevPlaces.filter(place => place._id !== item._id));
        })
        .catch((error) => {
          console.error('Error deleting place:', error);
        });
    }
  }

  const handleDeleteThing = (item) => {
    const confirmation = window.confirm(`Are you sure you want to delete ${item.name} ? `);
    console.log(`Job to be deleted: ${JSON.stringify(item)}`)
    if (confirmation) {
      dispatch(deleteThing(item._id))
        .then(() => {
          setThings(prevThings => prevThings.filter(thing => thing._id !== item._id));
        })
        .catch((error) => {
          console.error('Error deleting thing:', error);
        });
    }
  }

  const handleDeleteEmotion = (item) => {
    const confirmation = window.confirm(`Are you sure you want to delete ${item.name} ? `);
    console.log(`Job to be deleted: ${JSON.stringify(item)}`)
    if (confirmation) {
      dispatch(deleteEmotion(item._id))
        .then(() => {
          setEmotions(prevEmotions => prevEmotions.filter(emotion => emotion._id !== item._id));
        })
        .catch((error) => {
          console.error('Error deleting thing:', error);
        });
    }
  }

  const handleDeletePrompt = (item) => {
    const confirmation = window.confirm(`Are you sure you want to delete ${item.prompt}?`);
    console.log(`Prompt to be deleted: ${JSON.stringify(item)}`);
    if (confirmation) {
      dispatch(deletePrompt(item._id))
        .then(() => {
          setPrompts(prevPrompts => prevPrompts.filter(prompt => prompt._id !== item._id));
        })
        .catch((error) => {
          console.error('Error deleting prompt:', error);
        });
    }
  }

  const handleVerifyJob = (item) => {
    try {
      dispatch(verifyJob(item._id))
        .then(() => {
          // Update the state locally after successful verification
          setJobs(prevJobs => prevJobs.filter(job => job._id !== item._id));
        })
        .catch((error) => {
          console.error('Error verifying job:', error);
        });
    } catch (error) {
      console.error('Error dispatching verifyJob:', error);
    }
  }

  const handleVerifyPlace = (item) => {
    try {
      dispatch(verifyPlace(item._id))
        .then(() => {
          // Update the state locally after successful verification
          setPlaces(prevPlaces => prevPlaces.filter(place => place._id !== item._id));
        })
        .catch((error) => {
          console.error('Error verifying place:', error);
        });
    } catch (error) {
      console.error('Error dispatching verifyPlace:', error);
    }
  }

  const handleVerifyThing = (item) => {
    try {
      dispatch(verifyThing(item._id))
        .then(() => {
          // Update the state locally after successful verification
          setThings(prevThings => prevThings.filter(thing => thing._id !== item._id));
        })
        .catch((error) => {
          console.error('Error verifying thing:', error);
        });
    } catch (error) {
      console.error('Error dispatching verifyThing:', error);
    }
  }

  const handleVerifyEmotion = (item) => {
    try {
      dispatch(verifyEmotion(item._id))
        .then(() => {
          // Update the state locally after successful verification
          setEmotions(prevEmotions => prevEmotions.filter(emotion => emotion._id !== item._id));
        })
        .catch((error) => {
          console.error('Error verifying emotion:', error);
        });
    } catch (error) {
      console.error('Error dispatching verifyThing:', error);
    }
  }

  const handleVerifyPrompt = (item) => {
    console.log(`Item: ${JSON.stringify(item)}`)
    try {
      dispatch(verifyPrompt(item._id))
        .then(() => {
          setPrompts(prevPrompts => prevPrompts.filter(prompt => prompt._id !== item._id));
        })
        .catch((error) => {
          console.error('Error verifying prompt:', error);
        });
    } catch (error) {
      console.error('Error dispatching verifyPrompt:', error);
    }
  }

  const handleEditJob = (editedItem) => {
    try {
      console.log("Hey! I'm editing here!");
      dispatch(editJob({ id: editedItem._id, incomingData: editedItem }))
        .then(() => {
          // Optionally, you can update the local state after successful editing
          setJobs(prevJobs =>
            prevJobs.map(job =>
              job._id === editedItem._id ? { ...job, ...editedItem } : job
            )
          );
        })
        .catch(error => {
          console.error('Error editing job:', error);
        });
    } catch (error) {
      console.error('Error dispatching editJob:', error);
    }
  };

  const handleEditPlace = (editedItem) => {
    try {
      console.log("Hey! I'm editing here!");
      dispatch(editPlace({ id: editedItem._id, incomingData: editedItem }))
        .then(() => {
          // Optionally, you can update the local state after successful editing
          setPlaces(prevPlaces =>
            prevPlaces.map(place =>
              place._id === editedItem._id ? { ...place, ...editedItem } : place
            )
          );
        })
        .catch(error => {
          console.error('Error editing job:', error);
        });
    } catch (error) {
      console.error('Error dispatching editJob:', error);
    }
  };

  const handleEditThing = (editedItem) => {
    try {
      console.log("Hey! I'm editing here!");
      dispatch(editThing({ id: editedItem._id, incomingData: editedItem }))
        .then(() => {
          // Optionally, you can update the local state after successful editing
          setThings(prevThings =>
            prevThings.map(thing =>
              thing._id === editedItem._id ? { ...thing, ...editedItem } : thing
            )
          );
        })
        .catch(error => {
          console.error('Error editing thing:', error);
        });
    } catch (error) {
      console.error('Error dispatching editThing:', error);
    }
  };

  const handleEditEmotion = (editedItem) => {
    try {
      console.log("Hey! I'm editing here!");
      dispatch(editEmotion({ id: editedItem._id, incomingData: editedItem }))
        .then(() => {
          // Optionally, you can update the local state after successful editing
          setEmotions(prevEmotions =>
            prevEmotions.map(emotion =>
              emotion._id === editedItem._id ? { ...emotion, ...editedItem } : emotion
            )
          );
        })
        .catch(error => {
          console.error('Error editing emotion:', error);
        });
    } catch (error) {
      console.error('Error dispatching editEmotion:', error);
    }
  };

  const handleEditPrompt = (editedItem) => {
    try {
      console.log("Hey! I'm editing here!");
      dispatch(editPrompt({ id: editedItem._id, incomingData: editedItem }))
        .then(() => {
          setPrompts(prevPrompts =>
            prevPrompts.map(prompt =>
              prompt._id === editedItem._id ? { ...prompt, ...editedItem } : prompt
            )
          );
        })
        .catch(error => {
          console.error('Error editing prompt:', error);
        });
    } catch (error) {
      console.error('Error dispatching editPrompt:', error);
    };
  };

  const handleEditPromptClick = (item) => {
    setEditedItem({ ...item });
  };

  const handleCancelEdit = () => {
    setEditedItem(null);
  };

  const handleSaveEdit = () => {
    handleEditPrompt(editedItem);
    setEditedItem(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const preventKeyboardInput = (event) => {
    event.preventDefault();
  }

  return (
    <Container
      style={{
        paddingBottom: 100
      }}
    >
      <Row>
        <h2>Verification</h2>
        <hr />
        <Col xs={12} id="jobsToVerify">
          <h2>Jobs</h2>
          <AdminItemTable items={jobs} handleVerify={handleVerifyJob} handleDelete={handleDeleteJob} handleEdit={handleEditJob} />
        </Col>
        <Col xs={12} id="placesToVerify">
          <h2>Places</h2>
          <AdminItemTable items={places} handleVerify={handleVerifyPlace} handleDelete={handleDeletePlace} handleEdit={handleEditPlace} />
        </Col>
        <Col xs={12} id="thingsToVerify">
          <h2>Things</h2>
          <AdminItemTable items={things} handleVerify={handleVerifyThing} handleDelete={handleDeleteThing} handleEdit={handleEditThing} />
        </Col>
        <Col xs={12} id="emotionsToVerify">
          <h2>Emotions</h2>
          <AdminItemTable items={emotions} handleVerify={handleVerifyEmotion} handleDelete={handleDeleteEmotion} handleEdit={handleEditEmotion} />
        </Col>

        <Col xs={12} id="promptsToVerify">
          <h2>Prompts</h2>
          <Container>
            {
              prompts.length === 0 ? (
                <Row>
                  <Col>
                    <p>No items to display</p>
                  </Col>
                </Row>
              ) : (
                prompts.map((prompt, index) => (
                  <>
                    <Row className='adminItemTableHeader'>
                      <Col sm={12} md={1}><strong>Index</strong></Col>
                      <Col sm={12} md={5}><strong>Prompt</strong></Col>
                      <Col sm={12} md={1}><strong>Players</strong></Col>
                      <Col sm={12} md={2}><strong>Creator</strong></Col>
                      <Col sm={6} md={1}><strong></strong></Col>
                      <Col sm={6} md={1}><strong></strong></Col>
                      <Col sm={6} md={1}><strong></strong></Col>
                    </Row>
                    <Row key={prompt._id} className='adminItemTableRow'>
                      <Col xs={12} md={1}><strong>{index + 1}</strong></Col>
                      <Col xs={12} md={5} className="firstColumnWhenSmall">
                        {editedItem && editedItem._id === prompt._id ? (
                          <>
                            <Form.Control
                              as="textarea"
                              name="prompt"
                              value={editedItem.prompt}
                              onChange={handleInputChange}
                            />
                            <Form.Label for="prompt">Please make your edit and click save</Form.Label>
                          </>
                        ) : (
                          <Form.Control
                            as="textarea"
                            rows={Math.max(prompt.prompt.split('\n').length, 2)}
                            readOnly
                            value={prompt.prompt}
                          />
                        )}

                      </Col>
                      <Col xs={12} md={1}>
                        {editedItem && editedItem._id === prompt._id ? (
                          <>
                            <Form.Control
                              type="number"
                              name="players"
                              min="1"
                              max="3"
                              value={editedItem.players}
                              onChange={handleInputChange}
                              onKeyDown={preventKeyboardInput}
                              onKeyPress={preventKeyboardInput}
                            />
                            <Form.Label for="players">1-3 players</Form.Label>
                          </>
                        ) : (
                          <>
                            <Form.Control
                              type="number"
                              name="players"
                              min="1"
                              max="3"
                              readOnly
                              value={prompt.players}
                              onChange={handleInputChange}
                            />
                          </>
                        )}
                      </Col>
                      <Col xs={12} md={2}>{prompt.creatorName}</Col>
                      <Col xs={6} md={1}>
                        {editedItem && editedItem._id === prompt._id ? (
                          <>
                            <Button variant="success" onClick={handleSaveEdit}>
                              Save
                            </Button>{' '}
                            <Button variant="danger" onClick={handleCancelEdit}>
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" onClick={() => handleEditPromptClick(prompt)}>
                              Edit
                            </Button>
                          </>
                        )}
                      </Col>
                      <Col xs={6} md={1}>
                        <Button
                          variant="success"
                          onClick={() => handleVerifyPrompt(prompt)}
                          disabled={prompt.verified}
                        >
                          Approve
                        </Button>
                      </Col>
                      <Col xs={6} md={1}>
                        <Button
                          variant="danger"
                          onClick={() => handleDeletePrompt(prompt)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </>
                )))
            }
          </Container>
        </Col>
      </Row>
    </Container >

  );
};

export default Admin;

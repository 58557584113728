import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Register from './pages/Register'
import Header from './components/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Footer from './components/Footer'
import Admin from './pages/Admin'
import About from './pages/About'
import Prompts from './pages/Prompts'
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
console.log("Frontend starting")


function Layout({ children }) {
  const location = useLocation();
  const noLayoutRoutes = ['/gamechanger'];

  return (
    <>
      {!noLayoutRoutes.includes(location.pathname) && <Header />}
      {children}
      {!noLayoutRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}

function App() {

  return (
    <>
      <Router>
        <Layout>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route
              path="/admin"
              element={
                <ProtectedRoute element={Admin} />
              }
            />
            <Route path='/about' element={<About />} />
            <Route path='/gamechanger' element={<Prompts />} />
          </Routes>
        </Layout>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App;